<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="login login-1 d-flex flex-column flex-lg-row flex-column-fluid bg-white"
      :class="{
        'login-signin-on': this.state == 'signin',
        'login-signup-on': this.state == 'signup',
        'login-signup-verification-on': this.state == 'signup-verification',
        'login-forgot-on': this.state == 'forgot',
        'login-forgot-repair-on': this.state == 'forgot_repair',
      }"
      id="kt_login"
    >
      <!--begin::Aside-->
      <div
        class="login-aside d-flex flex-column flex-row-auto"
        style="background-color: #fbfcff"
      >
        <div class="d-flex flex-column-auto flex-column pt-lg-40 pt-15">
          <a href="#" class="text-center mb-10">
            <img src="/media/logos/natlogger.png" class="max-h-70px" alt="" />
          </a>
          <h3
            class="font-weight-bolder text-center font-size-h4 font-size-h1-lg"
            style="color: #3f4a72"
          >
            {{ $t("AUTH.FACE.WELCOME_TO_NATLOGGER") }} <br />
          </h3>
          <!-- <span class="font-weight-bold text-center" style="color: #3f4a72">{{
            $t("AUTH.FACE.THE_ULTIMATE_SAAS")
          }}</span> -->
        </div>
        <div
          class="aside-img d-flex flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center"
          :style="{ backgroundImage: `url(${backgroundImage})` }"
        ></div>
      </div>
      <!--begin::Aside-->
      <!--begin::Content-->
      <div
        class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto"
      >
        <div class="d-flex flex-column-fluid flex-center">
          <!--begin::Signin-->
          <div class="login-form login-signin">
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_signin_form"
              @submit.stop.prevent="onSubmitLogin()"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <h3
                  class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                >
                  {{ $t("AUTH.FACE.WELCOME_TO_NATLOGGER") }}
                </h3>
                <span class="text-muted font-weight-bold font-size-h4"
                  >{{ $t("AUTH.LOGIN.DONT_HAVE_AN_ACCOUNT_YET") }}
                  <a
                    id="kt_login_signup"
                    style="cursor: pointer"
                    class="text-primary font-weight-bolder"
                    @click="showForm('signup')"
                    >{{ $t("AUTH.LOGIN.SIGN_UP") }}</a
                  ></span
                >
              </div>

              <div
                role="alert"
                v-if="errors && errors.length > 0"
                class="alert fade alert-danger danger-limit show"
              >
                <div class="alert-text" v-for="(error, i) in errors" :key="i">
                  {{ error }}
                </div>
              </div>

              <div class="form-group">
                <label class="font-size-h6 font-weight-bolder text-dark">{{
                  $t("AUTH.INPUT.EMAIL")
                }}</label>
                <div
                  id="example-input-group-1"
                  label=""
                  label-for="example-input-1"
                >
                  <input
                    class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                    type="text"
                    name="email"
                    ref="email"
                    v-model="form.email"
                  />
                </div>
              </div>
              <div class="form-group">
                <div class="d-flex justify-content-between mt-n5">
                  <label
                    class="font-size-h6 font-weight-bolder text-dark pt-5"
                    >{{ $t("AUTH.INPUT.PASSWORD") }}</label
                  >
                  <a
                    class="text-primary font-size-h6 font-weight-bolder text-hover-primary pt-5"
                    id="kt_login_forgot"
                    style="cursor: pointer"
                    @click="showForm('forgot')"
                    >{{ $t("AUTH.FORGOT.TITLE") }}</a
                  >
                </div>
                <div
                  id="example-input-group-2"
                  label=""
                  label-for="example-input-2"
                >
                  <input
                    class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                    type="password"
                    name="password"
                    ref="password"
                    v-model="form.password"
                    autocomplete="off"
                  />
                </div>
              </div>
              <div class="pb-lg-0 pb-5">
                <button
                  ref="kt_login_signin_submit"
                  class="btn btn-primary font-weight-bolder font-size-h6 px-15 py-4 my-3 mr-3"
                >
                  {{ $t("AUTH.LOGIN.SIGN_IN") }}
                </button>
              </div>
              <!-- <label class="font-size-h6 font-weight-bolder text-dark">{{
                $t("AUTH.LOGIN.OR_SIGN_IN_WITH")
              }}</label>
              <div class="pb-lg-0 pb-5">
                <button
                  v-for="provider in providers"
                  :key="provider.key"
                  type="button"
                  class="btn btn-light-primary font-weight-bolder px-8 py-4 my-3 mr-3 font-size-lg"
                  @click="providerLogin(provider)"
                  v-bind:class="{
                    'btn kt-btn': true,
                    'btn-primary': provider.name === 'Facebook',
                    'btn-danger': provider.name === 'Google',
                    'btn-info': provider.name === 'LinkedIn',
                  }"
                >
                  <span class="svg-icon svg-icon-md">
                    <i
                      v-bind:class="{
                        fab: true,
                        'fa-facebook-f': provider.name === 'Facebook',
                        'fa-google': provider.name === 'Google',
                        'fa-linkedin': provider.name === 'LinkedIn',
                      }"
                    ></i> </span
                  >{{ provider.name }}
                </button>
              </div> -->
            </form>
          </div>
          <!--end::Signin-->
          <!--begin::Signup-->
          <div class="login-form login-signup">
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_signup_form"
              @submit.stop.prevent="onSubmitRegister()"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <h3
                  class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                >
                  {{ $t("AUTH.REGISTER.TITLE") }}
                </h3>
                <p class="text-muted font-weight-bold font-size-h4">
                  {{ $t("AUTH.REGISTER.DESC") }}
                </p>
              </div>
              <div class="form-group">
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="text"
                  :placeholder="$t('PROFILE.NAME')"
                  name="name"
                  ref="name"
                  autocomplete="off"
                />
              </div>
              <div class="form-group">
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="text"
                  :placeholder="$t('PROFILE.SURNAME')"
                  name="surname"
                  ref="surname"
                  autocomplete="off"
                />
              </div>
              <div class="form-group">
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="email"
                  :placeholder="$t('AUTH.INPUT.EMAIL')"
                  name="email"
                  ref="remail"
                  autocomplete="off"
                />
              </div>
              <div class="form-group">
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="password"
                  :placeholder="$t('AUTH.INPUT.PASSWORD')"
                  name="password"
                  ref="rpassword"
                  autocomplete="off"
                />
              </div>
              <div class="form-group">
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="password"
                  :placeholder="$t('AUTH.INPUT.CONFIRM_PASSWORD')"
                  name="cpassword"
                  ref="cpassword"
                  autocomplete="off"
                />
              </div>
              <!--<div class="form-group">
                <label class="checkbox mb-0">
                  <input type="checkbox" name="agree" />
                  <span class="mr-2"></span>
                  I Agree the
                  <a href="#" class="ml-2">terms and conditions</a>.
                </label>
              </div>-->
              <div class="form-group d-flex flex-wrap pb-lg-0 pb-3">
                <button
                  ref="kt_login_signup_submit"
                  class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
                  style="width: 150px"
                >
                  {{ $t("AUTH.GENERAL.SUBMIT_BUTTON") }}
                </button>
                <button
                  type="button"
                  id="kt_login_signup_cancel"
                  class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
                  @click="showForm('signin')"
                >
                  {{ $t("COMMON.CANCEL") }}
                </button>
              </div>
            </form>
          </div>
          <!--end::Signup-->
          <!--begin::Forgot-->
          <div class="login-form login-forgot">
            <!--begin::Form-->
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_forgot_form"
              ref="kt_login_forgot_form"
              @submit.stop.prevent="onSubmitForgot"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <h3
                  class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                >
                  {{ $t("AUTH.FORGOT.TITLE") }}
                </h3>
                <p class="text-muted font-weight-bold font-size-h4">
                  {{ $t("AUTH.FORGOT.DESC") }}
                </p>
              </div>
              <div class="form-group">
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="email"
                  :placeholder="$t('AUTH.INPUT.EMAIL')"
                  v-model="forgot.email"
                  name="email"
                  autocomplete="off"
                />
              </div>
              <div class="form-group d-flex flex-wrap pb-lg-0">
                <button
                  type="button"
                  id="kt_login_forgot_submit"
                  class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
                  @click="onSubmitForgot"
                >
                  {{ $t("AUTH.GENERAL.SUBMIT_BUTTON") }}
                </button>
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
                  @click="showForm('signin')"
                >
                  {{ $t("COMMON.CANCEL") }}
                </button>
              </div>
            </form>
          </div>
          <!--end::Forgot-->

          <!--begin::Sign up verification-->
          <div class="login-form login-signup-verification">
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_signup_verification_form"
              ref="kt_login_signup_verification_form"
              @submit.stop.prevent="onSubmitSignUpVerification"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <h3
                  class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                >
                  {{ $t("AUTH.REGISTER.TITLE") }}
                </h3>
                <p class="text-muted font-weight-bold font-size-h4">
                  {{ $t("AUTH.REGISTER.VERIFICATION_CODE") }}
                </p>
              </div>
              <div class="form-group">
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="email"
                  :placeholder="
                    $t('AUTH.REGISTER.VERIFICATION_CODE_PLACEHOLDER')
                  "
                  name="email"
                  v-model="register.verificationCode"
                  autocomplete="off"
                />
              </div>
              <div class="form-group d-flex flex-wrap pb-lg-0">
                <button
                  type="button"
                  id="kt_verification_submit"
                  class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
                  @click="onSubmitSignUpVerification"
                >
                  {{ $t("AUTH.GENERAL.SUBMIT_BUTTON") }}
                </button>
                <button
                  type="button"
                  id="kt_verification_cancel"
                  class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
                  @click="showForm('signin')"
                >
                  {{ $t("COMMON.CANCEL") }}
                </button>
              </div>
            </form>
          </div>
          <!--end::Sign up verification-->

          <!--begin::Forgot-repair-->
          <div class="login-form login-forgot-repair">
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_forgot_repair_form"
              ref="kt_login_forgot_repair_form"
              @submit.stop.prevent="onSubmitRepair"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <h3
                  class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                >
                  {{ $t("AUTH.REPAIR.PASSWORD_REPAIR") }}
                </h3>
                <p class="text-muted font-weight-bold font-size-h4">
                  {{ $t("AUTH.REPAIR.ENTER_YOUR_KNOWN_DETAILS") }}
                </p>
              </div>
              <div class="form-group">
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  :placeholder="
                    $t('AUTH.REGISTER.VERIFICATION_CODE_PLACEHOLDER')
                  "
                  type="text"
                  name="verificationCode"
                  v-model="repair.code"
                  autocomplete="off"
                />
              </div>

              <div class="form-group">
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="password"
                  :placeholder="$t('AUTH.INPUT.PASSWORD')"
                  name="password"
                  v-model="repair.password"
                  autocomplete="off"
                />
              </div>
              <div class="form-group">
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="password"
                  :placeholder="$t('AUTH.INPUT.CONFIRM_PASSWORD')"
                  name="cpassword"
                  v-model="repair.passwordConfirmation"
                  autocomplete="off"
                />
              </div>

              <div class="form-group d-flex flex-wrap pb-lg-0">
                <button
                  type="button"
                  id="kt_forgot-repair_submit"
                  class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
                  @click="onSubmitRepair"
                >
                  {{ $t("AUTH.GENERAL.SUBMIT_BUTTON") }}
                </button>
                <button
                  type="button"
                  id="kt_forgot-repair_cancel"
                  class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
                  @click="showForm('signin')"
                >
                  {{ $t("COMMON.CANCEL") }}
                </button>
              </div>
            </form>
          </div>
          <!--end::Forgot-repair-->
        </div>
        <!--begin::Content footer-->
        <div
          class="d-flex justify-content-lg-start justify-content-center align-items-end py-7 py-lg-0"
        >
          <!-- <a
            href="https://natlogger.com/pricing"
            class="text-primary font-weight-bolder font-size-h5"
            >{{ $t("AUTH.FACE.PRICING") }}</a
          > -->
          <a
            href="https://natlogger.com/blog"
            class="text-primary ml-10 font-weight-bolder font-size-h5"
            >{{ $t("AUTH.FACE.BLOG") }}</a
          >
          <a
            href="https://natlogger.com/contacts"
            class="text-primary ml-10 font-weight-bolder font-size-h5"
            >{{ $t("AUTH.FACE.CONTACTS") }}</a
          >
          <b-dropdown
            size="sm"
            variant="link"
            toggle-class="text-primary ml-10 font-weight-bolder font-size-h5 lang-b-drop"
            right
            dropup
            no-flip
            :text="$t('TRANSLATOR.LANGUAGE')"
          >
            <b-dropdown-text tag="div" class="min-w-md-175px">
              <KTDropdownLanguage></KTDropdownLanguage>
            </b-dropdown-text>
          </b-dropdown>
        </div>
        <!--end::Content footer-->
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<script>
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import {
  LOGIN,
  LOGOUT,
  REGISTER,
  SET_ERROR,
} from "@/core/services/store/auth.module";
import { API_REQUEST } from "@/core/services/store/api.module";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

import KTUtil from "@/assets/js/components/util";
import { mapGetters, mapState } from "vuex";
import Swal from "sweetalert2";
import Application from "@/application";

import KTDropdownLanguage from "../../layout/extras/dropdown/DropdownLanguage";
import i18nService from "@/core/services/i18n.service.js";

export default {
  name: "login-1",
  components: { KTDropdownLanguage },
  data() {
    return {
      state: "signin",
      form: {
        email: "",
        password: "",
      },
      forgot: {
        email: "",
        inProgress: false,
      },
      repair: {
        code: "",
        password: "",
        passwordConfirmation: "",
        inProgress: false,
      },
      register: {
        verificationCode: "",
      },

      languages: i18nService.languages,
      targetTariffId: null,

      // in progress means there is no possibility to press button again
      loginInProgress: false,
      registrationInProgress: false,
      providers: [],
      autoApprove: false,
    };
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
    ...mapGetters(["currentUser"]),

    backgroundImage() {
      return process.env.BASE_URL + "media/svg/illustrations/nlg.svg";
    },
  },

  created: async function () {
    // change page state depending on address
    switch (this.$router.currentRoute.name) {
      case "register":
        this.state = "signup";
        break;
      case "repair":
        this.state = "forgot";
        break;
    }

    this.loadOauthProviders();

    let urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("invitationId")) {
      localStorage.setItem("invitationId", urlParams.get("invitationId"));
    }
    // set tariff (selected on natlogger.com page)
    if (urlParams.has("t")) {
      this.targetTariffId = urlParams.get("t");
    }
  },

  mounted() {
    const signin_form = KTUtil.getById("kt_login_signin_form");
    const signup_form = KTUtil.getById("kt_login_signup_form");
    const forgot_form = KTUtil.getById("kt_login_forgot_form");
    const forgot_repair_form = KTUtil.getById("kt_login_forgot_repair_form");

    this.fv = formValidation(signin_form, {
      fields: {
        email: {
          validators: {
            notEmpty: {
              message: this.$t("AUTH.REGISTER.EMAIL_IS_REQUIRED"),
            },
          },
        },
        password: {
          validators: {
            notEmpty: {
              message: this.$t("AUTH.REGISTER.PASSWORD_IS_REQUIRED"),
            },
            stringLength: {
              min: 3,
              max: 50,
              message: this.$t("AUTH.REGISTER.PASSWORD_MUST_HAVE_LEAST"),
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    });

    this.fv1 = formValidation(signup_form, {
      fields: {
        name: {
          validators: {
            notEmpty: {
              message: this.$t("AUTH.VALIDATION.NAME_REQUIRED"),
            },

            stringLength: {
              min: 1,
              max: 35,
              message: this.$t("AUTH.REGISTER.NAME_MUST_HAVE"),
            },
          },
        },
        surname: {
          validators: {
            notEmpty: {
              message: this.$t("AUTH.VALIDATION.SURNAME_REQUIRED"),
            },
            stringLength: {
              min: 1,
              max: 35,
              message: this.$t("AUTH.REGISTER.SURNAME_MUST_HAVE"),
            },
          },
        },
        email: {
          validators: {
            notEmpty: {
              message: this.$t("AUTH.VALIDATION.EMAIL_REQUIRED"),
            },
            emailAddress: {
              message: this.$t("AUTH.VALIDATION.EMAIL_FORMAT"),
            },
          },
        },
        password: {
          validators: {
            notEmpty: {
              message: this.$t("AUTH.REGISTER.PASSWORD_IS_REQUIRED"),
            },
            stringLength: {
              min: 3,
              max: 50,
              message: this.$t("AUTH.REGISTER.PASSWORD_MUST_HAVE_LEAST"),
            },
          },
        },
        cpassword: {
          validators: {
            notEmpty: {
              message: this.$t("AUTH.REGISTER.PASSWORD_IS_REQUIRED"),
            },
            stringLength: {
              min: 3,
              max: 50,
              message: this.$t("AUTH.REGISTER.PASSWORD_MUST_HAVE_LEAST"),
            },
            identical: {
              compare: function () {
                return signup_form.querySelector('[name="password"]').value;
              },
              message: this.$t("USERS.PASSWORD_MISMATCH"),
            },
          },
        },
        /*agree: {
          validators: {
            notEmpty: {
              message: "You should agree terms and conditions"
            }
          }
        }*/
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    });

    this.fv2 = formValidation(forgot_form, {
      fields: {
        email: {
          validators: {
            notEmpty: {
              message: this.$t("AUTH.VALIDATION.EMAIL_REQUIRED"),
            },
            emailAddress: {
              message: this.$t("AUTH.VALIDATION.EMAIL_FORMAT"),
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    });

    this.fv3 = formValidation(forgot_repair_form, {
      fields: {
        verificationCode: {
          validators: {
            notEmpty: {
              message: this.$t("AUTH.REGISTER.VERIFICATION_CODE_MUST_HAVE"),
            },

            stringLength: {
              min: 6,
              max: 6,
              message: this.$t("AUTH.REGISTER.VERIFICATION_CODE_MUST_HAVE"),
            },
          },
        },
        password: {
          validators: {
            notEmpty: {
              message: this.$t("AUTH.REGISTER.PASSWORD_IS_REQUIRED"),
            },
            stringLength: {
              min: 3,
              max: 50,
              message: this.$t("AUTH.REGISTER.PASSWORD_MUST_HAVE_LEAST"),
            },
          },
        },
        cpassword: {
          validators: {
            notEmpty: {
              message: this.$t("AUTH.REGISTER.PASSWORD_IS_REQUIRED"),
            },
            identical: {
              compare: function () {
                return forgot_repair_form.querySelector('[name="password"]')
                  .value;
              },
              message: this.$t("USERS.PASSWORD_MISMATCH"),
            },
            stringLength: {
              min: 3,
              max: 50,
              message: this.$t("AUTH.REGISTER.PASSWORD_MUST_HAVE_LEAST"),
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    });
  },
  methods: {
    showForm(form) {
      this.state = form;
      var form_name = "kt_login_" + form + "_form";
      KTUtil.animateClass(
        KTUtil.getById(form_name),
        "animate__animated animate__backInUp"
      );
    },

    loadOauthProviders: async function () {
      await this.$store
        .dispatch(API_REQUEST, {
          resource:
            "/platform/api/oauth/providers?redirectUri=" +
            window.location.origin +
            "/oauth/",
          requestType: "GET",
        })
        .then((response) => (this.providers = response))
        .catch(this.onError);
    },

    popup: function (options) {
      var screenX =
          typeof window.screenX != "undefined"
            ? window.screenX
            : window.screenLeft,
        screenY =
          typeof window.screenY != "undefined"
            ? window.screenY
            : window.screenTop,
        outerWidth =
          typeof window.outerWidth != "undefined"
            ? window.outerWidth
            : document.body.clientWidth,
        outerHeight =
          typeof window.outerHeight != "undefined"
            ? window.outerHeight
            : document.body.clientHeight - 22,
        width = options.width,
        height = options.height,
        left = parseInt(screenX + (outerWidth - width) / 2, 10),
        top = parseInt(screenY + (outerHeight - height) / 2.5, 10),
        features =
          "width=" +
          width +
          ",height=" +
          height +
          ",left=" +
          left +
          ",top=" +
          top;
      return window.open(options.url, "oauth", features);
    },

    providerLogin: function (provider) {
      window.location = provider.url + provider.id;
    },
    tokenByCode: async function (provider, code, win) {
      await this.$store
        .dispatch(API_REQUEST, {
          resource: "/platform/api/oauth/token-by-code",
          requestType: "POST",
          dto: {
            authProviderId: provider,
            code: code,
          },
        })
        .then((response) => {
          localStorage.setItem("id_token", response.token);
          win.close();
          window.location.href =
            "https://app.natlogger.com/monitoring/greeting";
        })
        .catch(this.onError);
    },

    // LOGIN fv
    onSubmitLogin() {
      this.fv.validate();

      this.fv.on("core.form.valid", () => {
        if (this.loginInProgress) return;

        this.loginInProgress = true;

        // clear existing token
        this.$store.dispatch(LOGOUT);

        // set spinner to submit button
        const submitButton = this.$refs["kt_login_signin_submit"];
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");

        let dto = {
          identifierValue: this.form.email,
          password: this.form.password,
          identifierTypeId: null,
        };

        if (dto.identifierValue !== null && dto.identifierValue.includes("@")) {
          dto.identifierTypeId = Application.constants.IDENTIFIER_TYPE.EMAIL.id;
        } else {
          dto.identifierTypeId = Application.constants.IDENTIFIER_TYPE.LOGIN.id;
        }

        // send login request
        this.$store
          .dispatch(LOGIN, dto)
          // go to which page after successfully login
          .then(() => {
            this.$router.push({
              name: "monitoring",
              params: { id: "greeting" },
            });
          })
          .catch(() => {
            this.loginInProgress = false;
          });

        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      });

      this.fv.on("core.form.invalid", () => {
        Swal.fire({
          title: "",
          text: this.$t("AUTH.VALIDATION.PLEASE_PROVIDE_CORRECT_DATA"),
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
      });
    },

    // FORGOT PASSWORD fv2
    onSubmitForgot() {
      this.fv2.validate();

      this.fv2.on("core.form.valid", () => {
        if (this.forgot.inProgress) return;

        this.forgot.inProgress = true;

        const model = {
          identifierTypeId: Application.constants.IDENTIFIER_TYPE.EMAIL.id,
          identifierValue: this.forgot.email,
        };

        this.$store
          .dispatch(API_REQUEST, {
            resource:
              "/platform/api/auth/send-verification-code-for-password-changing",
            requestType: "POST",
            dto: model,
          })
          .then(() => {
            this.$bvToast.toast("Code was sent to your email", {
              title: this.$t("COMMON.INFO"),
              variant: "info",
              autoHideDelay: 5000,
            });
            this.showForm("forgot_repair");
          })
          .catch(this.onError)
          .finally(() => {
            this.forgot.inProgress = false;
          });
      });

      this.fv2.on("core.form.invalid", () => {
        Swal.fire({
          title: "",
          text: this.$t("AUTH.VALIDATION.PLEASE_PROVIDE_CORRECT_DATA"),
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
      });
    },

    // FORGOT REPAIR fv3
    onSubmitRepair() {
      this.fv3.validate();

      this.fv3.on("core.form.valid", () => {
        if (this.repair.inProgress) return;

        this.repair.inProgress = true;

        const model = {
          identifierTypeId: Application.constants.IDENTIFIER_TYPE.EMAIL.id,
          identifierValue: this.forgot.email,
          verificationCode: this.repair.code,
          newPassword: this.repair.password,
        };

        // set spinner to submit button
        //const submitButton = this.$refs["kt_login_signup_submit"];
        //submitButton.classList.add("spinner", "spinner-light", "spinner-right");

        this.$store
          .dispatch(API_REQUEST, {
            resource:
              "/platform/api/auth/change-password-via-verification-code",
            requestType: "POST",
            dto: model,
          })
          .then(() => {
            this.$bvToast.toast(
              this.$t("AUTH.REPAIR.PASSWORD_SUCCESSFULLY_CHANGED"),
              {
                title: this.$t("COMMON.SUCCESS"),
                variant: "success",
                autoHideDelay: 15000,
              }
            );
            this.showForm("signin");
          })
          .catch(this.onError)
          .finally(() => {
            this.repair.inProgress = false;
          });

        /*submitButton.classList.remove(
                "spinner",
                "spinner-light",
                "spinner-right"
            );*/
      });

      this.fv3.on("core.form.invalid", () => {
        Swal.fire({
          title: "",
          text: this.$t("AUTH.VALIDATION.PLEASE_PROVIDE_CORRECT_DATA"),
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
      });
    },

    loginAutomatically(identifierTypeId, identifierValue, password) {
      let loginDto = {
        identifierValue: identifierValue,
        password: password,
        identifierTypeId: identifierTypeId,
      };

      this.$store.dispatch(LOGIN, loginDto).then(() =>
        this.$router.push({
          name: "monitoring",
          params: { id: "greeting" },
        })
      );
    },

    onSubmitSignUpVerification() {
      if (
        this.register.verificationCode === null ||
        this.register.verificationCode.length !== 6
      ) {
        this.$bvToast.toast(
          this.$t("AUTH.REGISTER.VERIFICATION_CODE_MUST_HAVE"),
          {
            title: this.$t("COMMON.INFO"),
            variant: "info",
            autoHideDelay: 5000,
          }
        );
        return;
      }

      const verifyModel = {
        identifierTypeId: Application.constants.IDENTIFIER_TYPE.EMAIL.id,
        identifierValue: this.$refs.remail.value,
        verificationCode: this.register.verificationCode,
      };

      this.$store
        .dispatch(API_REQUEST, {
          resource: "/platform/api/identifier/verify",
          requestType: "POST",
          dto: verifyModel,
        })
        .then(() => {
          this.$bvToast.toast(
            this.$t("AUTH.REGISTER.VALIDATION_SUCCESSFULLY"),
            {
              title: this.$t("COMMON.INFO"),
              variant: "info",
              autoHideDelay: 5000,
            }
          );

          this.loginAutomatically(
            Application.constants.IDENTIFIER_TYPE.EMAIL.id,
            this.$refs.remail.value,
            this.$refs.rpassword.value
          );
        })
        .catch(this.onError);
    },

    onError: function (response) {
      if (response && response.config) response = response.data;

      this.$bvToast.toast(
        response && response.message
          ? response.message
          : this.$t("COMMON.UNKNOWN_ERROR"),
        {
          title: this.$t("COMMON.ERROR"),
          variant: "danger",
          autoHideDelay: 5000,
        }
      );
    },

    onSubmitRegister() {
      this.fv1.validate();

      this.fv1.on("core.form.valid", () => {
        if (this.registrationInProgress) {
          return;
        }

        this.registrationInProgress = true;

        const signUpModel = {
          identifiers: [
            {
              identifierTypeId: Application.constants.IDENTIFIER_TYPE.EMAIL.id,
              value: this.$refs.remail.value,
              primary: true,
            },
          ],
          fields: {
            surname: this.$refs.surname.value,
            name: this.$refs.name.value,
            avatar: null,
            t: this.targetTariffId,
          },
          password: this.$refs.rpassword.value,
          passwordConfirmation: this.$refs.cpassword.value,
          agree: true,
        };

        // clear existing errors
        this.$store.dispatch(LOGOUT);

        // set spinner to submit button
        //const submitButton = this.$refs["kt_login_signup_submit"];
        //submitButton.classList.add("spinner", "spinner-light", "spinner-right");

        // send register request
        this.$store
          .dispatch(REGISTER, signUpModel)
          .then(() => {
            if (this.autoApprove) {
              this.loginAutomatically(
                signUpModel.identifiers[0].identifierTypeId,
                signUpModel.identifiers[0].value,
                signUpModel.password
              );
            } else {
              this.state = "signup-verification";
            }
          })
          .catch(this.onError)
          .finally(() => {
            this.registrationInProgress = false;
          });

        /*submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );*/
      });

      this.fv1.on("core.form.invalid", () => {
        Swal.fire({
          title: "",
          text: this.$t("AUTH.VALIDATION.PLEASE_PROVIDE_CORRECT_DATA"),
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
      });
    },
  },
};
</script>

<style>
.lang-b-drop {
  padding: 0 0 1px;
  margin-left: auto;
  float: right;
}
</style>
